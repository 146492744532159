<script>
import {authMethods, notificationMethods} from "@/state/helpers";
import {required, email} from "vuelidate/lib/validators";
import LogoSvg from "@/components/Landing/LogoSvg.vue";

/**
 * Forgot password component
 */
export default {
	page: {
		title: "Forgot Password",
	},
	components: {
		LogoSvg
	},
	data() {
		return {
			email: "",
			submitted: false,
			tryingToReset: false,
		};
	},
	validations: {
		email: {required, email},
	},
	computed: {
		notification() {
			return this.$store ? this.$store.state.notification : null;
		},
		notificationAutoCloseDuration() {
			return this.$store && this.$store.state.notification ? 5 : 0;
		},
	},
	methods: {
		...authMethods,
		...notificationMethods,
		// Try to register the user in with the email, fullname
		// and password they provided.
		tryToReset() {
			this.submitted = true;
			// stop here if form is invalid
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			} else {
				this.resetPassword({
					email: this.email,
				})
			}
		},
	},
};
</script>

<template>
	<div class="account-pages my-5 pt-sm-5">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-md-8 col-lg-6 col-xl-5">
					<div class="text-center mb-4">
						<router-link to="/" class="auth-logo mb-5 d-block">
							<LogoSvg />
						</router-link>

						<h4>{{ $t('resetpassword.title') }}</h4>
					</div>

					<div class="card">
						<div class="card-body p-4">
							<div class="p-3">
								<b-alert
									:variant="notification.type"
									class="mt-3"
									v-if="notification.message"
									:show="notificationAutoCloseDuration"
									dismissible
								>{{ notification.message }}
								</b-alert>
								<form action="" @submit.prevent="tryToReset">
									<div class="form-group mb-4">
										<label>{{ $t('resetpassword.form.email.label') }}</label>
										<div class="input-group mb-3 bg-soft-light input-group-lg rounded-lg">
											<div class="input-group-prepend">
                        <span class="input-group-text border-light text-muted">
                          <i class="ri-mail-line"></i>
                        </span>
											</div>
											<input
												v-model="email"
												type="email"
												class="form-control bg-soft-light border-light"
												:class="{ 'is-invalid': submitted && $v.email.$error }"
												:placeholder="$t('login.form.email.placeholder')"
											/>
											<div v-if="submitted && $v.email.$error" class="invalid-feedback">
                        <span
							v-if="!$v.email.required"
						>{{ $t('resetpassword.form.email.validation.required') }}</span>
												<span
													v-if="!$v.email.email"
												>{{ $t('resetpassword.form.email.validation.invalid') }}</span>
											</div>
										</div>
									</div>

									<div>
										<button
											class="btn btn-primary btn-block"
											type="submit"
										>{{ $t('resetpassword.form.button.text') }}
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>

					<div class="mt-5 text-center">
						<p>
							{{ $t('resetpassword.signintext') }}
							<router-link
								tag="a"
								to="/login"
								class="font-weight-medium text-primary"
							>{{ $t('resetpassword.signinlink') }}
							</router-link>
						</p>
						<p>
							© {{ new Date().getFullYear() }}
							<i
								class="mdi mdi-heart text-danger"
							></i>
							Romeo
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>